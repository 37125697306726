    .mymodal {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 90%;
        transform: translate(-50%, -50%);
        border: 1px solid #ccc;
        background: #fff;
        font-family: 'Poppins', sans-serif;
        max-height: calc(100vh);
        overflow-y: auto;

        border-radius: 4px;
        outline: none;
        padding: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    img .buttonSize {
        cursor: "pointer  !important";
        width: "40%" !important;
        height: "40%" !important;
    }


    .nav-logo {
        float: left !important;
        margin-top: -15px !important;
    }

    .margin {
        margin-top: 10px;
        font-size: 20px;
    }

    .fill-window {
        height: 80%;
        width: 100%;
    }

    .first-letter {
        font-size: 30px;
        line-height: 30px;
        margin-right: 5px;
        margin-top: 20000px;
        color: darkgreen;
    }


    .buttontop {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 80px;
        cursor: "pointer";
        width: "40%";
        height: "40%";
    }

    .App {
        /* display: flex; */
        justify-content: space-around;
        font-family: sans-serif;
        text-align: center;
        padding-top: 40px;
    }

    .time {
        font-size: 32px;
    }



    @media screen and (min-width: 1024px) and (max-height: 1310px) {

        /* Laptop */
        .mymodal {
            position: fixed;
            top: 50%;
            left: 55%;
            width: 25%;
            transform: translate(-50%, -50%);
            border: 1px solid #ccc;
            background: #fff;
            font-family: 'Poppins', sans-serif;
            max-height: calc(100vh);
            overflow-y: auto;
            border-radius: 4px;
            outline: none;
            padding: 20px;
            padding-left: 20px;
            padding-right: 20px;
        }

        img .buttonSize {
            cursor: "pointer  !important";
            width: "40%" !important;
            height: "40%" !important;
        }

        .first-letter {
            font-size: 40px;
            line-height: 40px;
            color: darkgreen;
            padding-top: 20000px;
            margin-right: 5px;

        }

        img .buttonSize {
            cursor: "pointer  !important";
            width: "40%" !important;
            height: "40%" !important;
        }


        .margin {
            margin-top: 40px;
            font-size: 30px;
        }

        .App {
            display: flex;
            text-align: center;
            padding-top: 20px;
        }

        .time {
            font-size: 32px;
        }

        .fill-window {
            height: 80%;
            width: 100%;
        }

    }

    @media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
        .mymodal {
            position: fixed;
            top: 50%;
            left: 50%;
            width: 90%;
            transform: translate(-50%, -50%);
            border: 1px solid #ccc;
            background: #fff;
            font-family: 'Poppins', sans-serif;
            max-height: calc(100vh);
            overflow-y: auto;
            border-radius: 4px;
            outline: none;
            padding: 20px;
            padding-left: 20px;
            padding-right: 20px;
        }

        img .buttonSize {
            cursor: "pointer  !important";
            width: "40%" !important;
            height: "40%" !important;
        }

        .first-letter {
            font-size: 20px;
            line-height: 50px;
            color: darkgreen;
        }

        .fill-window {
            height: 80%;
            width: 100%;
        }

    }

    /* 
 */

    .myoverlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.75);
    }

    img .buttonSize {
        cursor: "pointer  !important";
        width: "40%" !important;
        height: "40%" !important;
    }



    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }

    .ReactModal__Overlay--after-open {
        opacity: 1;
        max-height: calc(100vh);
        overflow-y: auto;
    }

    .ReactModal__Overlay--before-close {
        opacity: 0;
    }

    .row {
        font-family: 'Poppins', sans-serif;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 40px;
        height: 80px;
    }


    @media (max-width:767px) {

        .navbar-brand img {
            height: 90px;
            width: 90px
        }

        .thumbnail,
        .image-size,
        src {
            height: 50%;
            width: 100%
        }




    }

    .navbar-toggle {
        float: left;
    }